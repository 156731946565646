<template>
    <div class="wrapper">
        <div class="logo">
            <img alt="Vue logo" src="../assets/logo.jpg" style="height:100px;border-radius:40%;overflow:hidden;border:2px solid white " />
        </div>
        <div>
            <el-divider direction="vertical" style="margin-bottom: -25px;height:50px">
            </el-divider>
            <el-row>
                <el-col :span="8">
                    <div class="grid-content ep-bg-purple" />
                </el-col>
                <el-col :span="8">
                    <div class="grid-content ep-bg-purple-light">
                        <el-divider border-style="dotted">
                        </el-divider>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content ep-bg-purple" />
                </el-col>
            </el-row>
        </div>
        <div v-if="visible" class="content">
            <div class="inner">
                <h1> Hello World </h1>
                <p>
                    You build it &nbsp;&bull;&nbsp; _ &nbsp;&bull;&nbsp; You run it.
                </p>
            </div>
        </div>
        <div>
            <el-row>
                <el-col :span="8">
                    <div class="grid-content ep-bg-purple" />
                </el-col>
                <el-col :span="8">
                    <div class="grid-content ep-bg-purple-light">
                        <el-divider border-style="dotted">
                        </el-divider>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content ep-bg-purple" />
                </el-col>
            </el-row>
        </div>
        <div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { reactive } from 'vue'
export default {
    name: "Home",
    components: {},
    data() {
        return {
            infoDrawer: ref(false),
            contactDrawer: ref(false),
            visible: false,
            form: reactive({
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: '',
            })
        };
    },
    mounted() {
        setTimeout(() => {
            this.visible = true
        }, 500);
    },
    methods: {
        goTarget(href) {
            if (href.startsWith("#/")) {
                window.open(href, "_self");
            } else {
                window.open(href, "_blank");
            }
        },
        onSubmit() {
            console.log('submit!')
        }
    }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>